import axiosApi from '@/service/modules/axios-api'
import { ISC_MICRO_SERVICE_PREFIX } from '@/service'
export default {
  // ticket登录(票据协议集成ISC时使用此方法登录)
  loginTicket (params) {
    // return axiosApi.get(ISC_MICRO_SERVICE_PREFIX + '/isc/ticket/login?ticket=' + params.ticket + '&service=' + params.operateUri, {}, false)
    return axiosApi.get(ISC_MICRO_SERVICE_PREFIX + '/isc/ticket/login', params, { isAuth: false })
  },
  // isc登出时通知后端
  logoutIsc (data) {
    return axiosApi.post(ISC_MICRO_SERVICE_PREFIX + '/isc/logout', data)
  },
  // access_token登录(Oauth2.0协议集成ISC时使用此方法登录)
  loginAcToken (params) {
    // return axiosApi.get('/oauth2/login?accessToken=' + params + '&scope=ASR_DEV', params, false)
    return axiosApi.get(ISC_MICRO_SERVICE_PREFIX + '/ticket/login/isc?accessToken=' + params + '&scope=ASR_DEV', params, { isAuth: false })
  }
}
